<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3>Calculate R value for outer wall by adding custom layers</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>


<div mat-dialog-content >
    
    <form [formGroup]="OuterWallAdvFG" novalidate >
    <mat-form-field appearance="outline">
        <mat-label>Select main material category</mat-label>
        <mat-select (selectionChange)="onChangeMaterial($event)" >
            <mat-option *ngFor="let list of rvalueadvancedmaterialData" [value]="list.mainmaterial">{{list.mainmaterial}}</mat-option>
        </mat-select>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource">

        <!-- Selected Column -->
        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef>
                {{'BEEC.BUILDINGENV_DETAILS.RVALUE_SELECTED_ELEMENT' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-radio-button [value]="element" (click)="selectedR($event, element)">
                </mat-radio-button>
            </td>
        </ng-container>


        <ng-container matColumnDef="MATERIAL SUB-CATEGORY 1">
            <th mat-header-cell *matHeaderCellDef> MATERIAL SUB-CATEGORY 1 </th>
            <td mat-cell *matCellDef="let element"> {{element.subcategory1}} </td>
        </ng-container>

        <ng-container matColumnDef="MATERIAL SUB-CATEGORY 2">
            <th mat-header-cell *matHeaderCellDef> MATERIAL SUB-CATEGORY 2 </th>
            <td mat-cell *matCellDef="let element"> {{element.subcategory2}} </td>
        </ng-container>

        <ng-container matColumnDef="DENSIDAD APARENTE">
            <th mat-header-cell *matHeaderCellDef> DENSIDAD APARENTE (kg/m³)</th>
            <td mat-cell *matCellDef="let element"> {{element.bulkdensity}} </td>
        </ng-container>

        <ng-container matColumnDef="CONDUCTIVIDAD TERMICA">
            <th mat-header-cell *matHeaderCellDef> CONDUCTIVIDAD TERMICA W/m.K </th>
            <td mat-cell *matCellDef="let element"> {{element.thermcalconductivityfrom}} </td>
        </ng-container>


        <ng-container matColumnDef="CONDUCTIVIDAD TERMICA(to)">
            <th mat-header-cell *matHeaderCellDef> CONDUCTIVIDAD TERMICA W/m.K (to) </th>
            <td mat-cell *matCellDef="let element"> {{element.thermcalconductivityto}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <div class="button-dialog" mat-dialog-actions>
        <button mat-raised-button color="primary" style="color: #fff;"  type="submit"  (click)="postcalculateAdvancedMaterial()" cdkFocusInitial>Ok</button>
    </div>
</form>
</div>