import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions, ChartType } from 'chart.js';
import jsPDF from 'jspdf';
import { Color, Label } from 'ng2-charts';
import { BEETComponent } from 'src/app/modules/BEET/beet.component';
import { BuildingenvelopedetailsComponent } from 'src/app/modules/BEET/buildingenvelopedetails/buildingenvelopedetails.component';
import { CO2EmissionsComponent } from 'src/app/modules/BEET/co2-emissions/co2-emissions.component';
import { GendetailsComponent } from 'src/app/modules/BEET/gendetails/gendetails.component';
import { HvacComponent } from 'src/app/modules/BEET/hvac/hvac.component';
import { LightingComponent } from 'src/app/modules/BEET/lighting/lighting.component';
import { PlugloadsComponent } from 'src/app/modules/BEET/plugloads/plugloads.component';
import { beetService } from '../services/beet.service';
import html2canvas from 'html2canvas';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { BeetreportComponent } from 'src/app/modules/BEET/beetreport/beetreport.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-beetreportpdf',
  templateUrl: './beetreportpdf.component.html',
  styleUrls: ['./beetreportpdf.component.scss']
})
export class BeetreportpdfComponent implements OnInit {
  genDetailsComponent: GendetailsComponent;
  buildingdetailsComponent: BuildingenvelopedetailsComponent;
  lightingDetailsComponent: LightingComponent;
  co2EmissionsDetailsComponent: CO2EmissionsComponent;
  hvacDetailsComponent: HvacComponent;
  plugLoaDetailsComponent: PlugloadsComponent;
  beetComponent: BEETComponent;
  beetReportComponent: BeetreportComponent;
  selectedcountryname: string;

  barChartLabels: Label[] = [['Total Energy', '[kWh/m²]'], ['Heating Energy','[m³N.G/m²]'], ['Electricity','[KWh/m²]'], ['Electric Peak', '[kW/m²]'], ['Total Cost','[$/m²]']];
  barChartLabels2: Label[] = ['Heating', 'Cooling', 'Lights', 'Plugs', 'Fans', 'Total'];
  barChartLabels3: Label[] = [['Annual Total Electric','CO₂ emissions','[metric tons]'], ['Annual Heating', 'Natural Gas CO₂ emissions','[metric tons]'], ['Total','CO₂ emissions', '[metric tons]']];
  barChartLabels4: Label[] = ['Heating', 'Cooling', 'Lights', 'Plugs', 'Fans', 'Total'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartOptionsperformance: ChartOptions = {
    responsive: true,
    legend: { display: false },
  };
  barChartOptions: ChartOptions = {
    responsive: true,
    legend: { display: true },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'ARS/year'
        }
      }]
    }
  };

  barChartOptions1: ChartOptions = {
    responsive: true,
    legend: { display: true }, 
  };
  legendPosition:"below";
  barChartOptions3: ChartOptions = {
    responsive: true,
    legend: { display: true},
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Equivalent CO₂ emissions, metric tons'
        }
      }]
    }
  };
  barChartOptions4: ChartOptions = {
    responsive: true,
    legend: { display: true },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Equivalent energy consumption, kWh'
        }
      }]
    }
  };

  barChartColors: Color[] = [
    {
      backgroundColor: '#FF7D00'
    },
    {
      backgroundColor: '#3cd070'
    }
  ];
  ispdfloading: boolean = false;
  isGenerating: boolean = false;
  curDate = new Date();
  pdfInputForm = new FormControl();
  checked = false;
  beetReportForm:FormGroup;


  constructor(private beetService: beetService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<BeetreportpdfComponent>) {
       
  } 
  ngOnInit(): void {  
    this.initiazeDialog();
    this.initTranslatiblStrings();
  }
  ngAfterViewInit() {
   // this.initiazeDialog();
  }

  initTranslatiblStrings() {
    this.barChartOptions4.scales.yAxes[0].scaleLabel.labelString=this.translate.instant('BEEC.REPORT.EQUIVALENT_ENERGY_CONSUMPTION');
    this.barChartOptions.scales.yAxes[0].scaleLabel.labelString=this.translate.instant('BEEC.REPORT.BARCHART_ARSYEAR');
    this.barChartOptions3.scales.yAxes[0].scaleLabel.labelString=this.translate.instant('BEEC.REPORT.EQUIVALENTCO2EMISSIONS');

    this.barChartLabels2 = [this.translate.instant('BEEC.REPORT.HEATING'),
    this.translate.instant('BEEC.REPORT.COOLING'),
    this.translate.instant('BEEC.REPORT.LIGHTS'), 
    this.translate.instant('BEEC.REPORT.PLUGS'), 
    this.translate.instant('BEEC.REPORT.FANS_AND_BLOWERS'), 
    this.translate.instant('BEEC.REPORT.TOTAL')];


    this.barChartLabels4 = [this.translate.instant('BEEC.REPORT.HEATING'),
    this.translate.instant('BEEC.REPORT.COOLING'),
    this.translate.instant('BEEC.REPORT.LIGHTS'), 
    this.translate.instant('BEEC.REPORT.PLUGS'), 
    this.translate.instant('BEEC.REPORT.FANS_AND_BLOWERS'), 
    this.translate.instant('BEEC.REPORT.TOTAL')];

    this.barChartLabels = [[this.translate.instant('BEEC.REPORT.TOTAL_ENERGY'), '[kWh/m²]'],
    [this.translate.instant('BEEC.REPORT.HEATING_ENERGY'), '[m³N.G/m²]'],
    [this.translate.instant('BEEC.REPORT.ELECTRICITY'), '[KWh/m²]'],
    [this.translate.instant('BEEC.REPORT.ELECTRIC_PEAK'), '[kW/m²]'],
    [this.translate.instant('BEEC.REPORT.TOTAL_COST'), '[$/m²]']];


    this.barChartLabels3 = [[this.translate.instant('BEEC.REPORT.ANNUAL_TOTAL_ELECTRIC'), this.translate.instant('BEEC.REPORT.CO2_EMISSIONS'), this.translate.instant('BEEC.REPORT.METRIC_TONS')],
    [this.translate.instant('BEEC.REPORT.ANNUAL_HEATING'), this.translate.instant('BEEC.REPORT.NATURALGAS_CO2'),  this.translate.instant('BEEC.REPORT.METRIC_TONS')],
    [this.translate.instant('BEEC.REPORT.TOTAL_GRAPH'), this.translate.instant('BEEC.REPORT.CO2_EMISSIONS'),  this.translate.instant('BEEC.REPORT.METRIC_TONS')]];
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }  
  onToggleChange(event) {
    console.log(this.pdfInputForm.value);
  } 
  initiazeDialog(): void {
    this.beetComponent = this.beetService.getBEETParentComponent();
    this.ispdfloading = true;
    this.beetComponent = this.beetService.getBEETParentComponent();
    this.selectedcountryname = this.beetComponent.selectedcountryname
    this.genDetailsComponent = this.beetComponent.genDetailsComponent;
    this.buildingdetailsComponent = this.beetComponent.buildingdetailsComponent;
    this.plugLoaDetailsComponent = this.beetComponent.plugLoaDetailsComponent;
    this.lightingDetailsComponent = this.beetComponent.lightingDetailsComponent;
    this.hvacDetailsComponent = this.beetComponent.hvacDetailsComponent;
    this.co2EmissionsDetailsComponent = this.beetComponent.co2EmissionsDetailsComponent;
    this.beetReportComponent= this.beetComponent.beetReportComponent;

    this.ispdfloading = false;
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

   getPDF() {
    this.isGenerating = true;
    //await this.delay(3000);
    let general = document.getElementById('pdf-general-section');
    let construction = document.getElementById('pdf-construction');
    let hvacpdf = document.getElementById('pdf-hvac');
    var tempwidth = general.offsetWidth;
    var pdf = new jsPDF('p', 'pt', [tempwidth+60, 1800]);

    pdf.setFontSize(30);
    pdf.setTextColor(231, 76, 60)
    pdf.setDrawColor(173, 216, 230)
    pdf.setCreationDate();
    this.generateCanvas(pdf, general, 30, false);
    this.generateCanvas(pdf, construction, 30, false);
    this.generateCanvas(pdf, hvacpdf, 30, true);
    this.isGenerating = false;
  };

  generateCanvas(pdf, sectionDiv, top_left_margin, savePDF) {
    var HTML_Width = sectionDiv.offsetWidth;
    var HTML_Height = sectionDiv.offsetHeight;
    console.log("HTML Weight height " + HTML_Width + "    " + HTML_Height);
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    html2canvas(sectionDiv).then(function (canvas) {
      canvas.getContext('2d');
      console.log(canvas.height + "  " + canvas.width);
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      if (savePDF != true) {
        pdf.addPage()
      }
      if (savePDF == true) {
        pdf.save("Beec-Report.pdf");
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


