<mat-toolbar class="mat-toolbar">
    <mat-toolbar-row class="d-flex justify-content-between">
        <h3>Enter Thickness in mm</h3>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content>
    <form [formGroup]="OuterWallAdvFG" novalidate>

        <mat-form-field class="w-100 mt-4 col-md-4" appearance="outline">
            <mat-label>Enter Thickness</mat-label>
            <input matInput formControlName="thickness" required>
        </mat-form-field>
        <div>
            <button type="button" class="btn btn-primary" (click)="accept()">ok</button>
        </div>
    </form>

</div>