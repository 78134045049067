<header>
    <nav class="navbar navbar-expand-md">
        <div class="container-fluid">

            <a class="navbar-brand" style="visibility: hidden;">
                <img style="width: 100%;" src="assets/images/Logos/UNEP-partnershiplogo.png">
            </a>

            <button class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse" data-target="#collapsingNavbar" (click)="showMenu = !showMenu">
                <span class="navbar-toggler-icon"> <i class="material-icons">menu</i></span>
            </button>
            <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" id="collapsingNavbar">
                <button class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse" data-target="#collapsingNavbar" (click)="showMenu = !showMenu">
                    <div class="close-icon py-1">✖</div>
                </button>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" (click)="naviagateToHome();showMenu = !showMenu" data-toggle="collapse" data-target=".navbar-collapse.show">Home</a>
                    </li>
                    <!-- <li class="nav-item" (click)="showMenu = !showMenu">
                        <a class="nav-link" href="#" data-toggle="collapse" data-target=".navbar-collapse.show">Tools</a>
                    </li> -->
                    <li class="nav-item" (click)="showMenu = !showMenu">
                        <a class="nav-link" href="https://c2e2.unepccc.org/who-we-are/" target="_blank"  data-target=".navbar-collapse.show">Contact</a>
                    </li>
                </ul>
            </div>
            <!-- <a class="search-link pull-right" [ngClass]="{'active' : showSearch}" (click)="showSearch = !showSearch">
                <span class="search-text">Search</span>
            </a> -->
            <div class="search-div" *ngIf="showSearch" [ngClass]="{'active' : showSearch}">
                <div class="search-inner">
                    <form class="search-form" action="/">
                        <div class="search-form-group">
                            <label class="search-label" for="s">Search a keyword, location etc.</label>
                            <input #msgFocus class="search-input" type="text" name="s" placeholder="Search a keyword, location etc.">
                            <button class="search-button">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </nav>
    <h1 class="text-center nav-heading">TOOLBOX</h1>
</header>