<footer class="p-site-footer">
    <div class="container">

        <div class="p-site-footer__top">
            <p class="p-site-footer__website-description">The Copenhagen Centre on Energy Efficiency (Copenhagen Centre) is dedicated to accelerating the uptake of energy efficiency policies and programmes at a global scale. In the context of the United Nations Secretary General’s Sustainable Energy
                for All (SEforALL) initiative, the Copenhagen Centre is the thematic hub for energy efficiency with the prime responsibility to support action towards the SEforALL energy efficiency target of doubling the global rate of improvement in
                energy efficiency by 2030. The Copenhagen Centre is institutionally part of the UNEP DTU Partnership.</p>
            <ul class="p-site-footer__partners-list p-site-footer__partners-list--unepdtu o-no-bullets">
                <li class="p-site-footer__partners-item p-site-footer__partners-item--unep">
                    <a class="p-site-footer__partners-link" title="UNEP" href="https://www.unenvironment.org/" target="_blank">UNEP</a>
                </li>
                <li class="p-site-footer__partners-item p-site-footer__partners-item--dtu">
                    <a class="p-site-footer__partners-link" title="DTU" href="https://www.dtu.dk/" target="_blank">DTU</a>
                </li>
            </ul>
        </div>

        <div class="p-site-footer__bottom">
            <div class="o-grid">

                <div class="o-grid__col o-grid__col-sm-6 o-grid__col-md-3">
                    <h2 class="p-site-footer__headline">Contact</h2>

                    <div itemscope="" itemtype="http://schema.org/Organization" class="c-address c-address--footer">
                        <span itemprop="name" class="c-address__name">Copenhagen Centre on Energy Efficiency</span>
                        <div itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress" class="c-address__address">
                            <span itemprop="streetAddress">Marmorvej 51</span>
                            <div>
                                <span itemprop="postalCode">2100</span>
                                <span itemprop="addressLocality">Copenhagen</span>
                            </div>
                            <span itemprop="addressCountry">Denmark</span>
                        </div>

                        <div class="c-address__telephone">
                            Tel: <span itemprop="telephone">+45 4533 5318</span>
                        </div>

                        <div class="c-address__email">
                            Email: <span itemprop="email"><a href="mailto:c2e2@dtu.dk">c2e2@dtu.dk</a></span>
                        </div>

                        <a href="https://goo.gl/maps/Eh5yXMiQ5VUwt3mC9" class="c-address__direction" target="_blank">Get
              directions</a>
                    </div>





                </div>

                <div class="o-grid__col o-grid__col-sm-6 o-grid__col-md-3">
                    <h2 class="p-site-footer__headline">Join us</h2>
                    <ul class="c-footer-menu__list " role="menu">
                        <li class="c-footer-menu__item  c-footer-menu__item--36505"><a href="https://c2e2.unepdtu.org/jobs-and-consultancies/">Jobs and Consultancies</a></li>
                        <li class="c-footer-menu__item  c-footer-menu__item--36127"><a href="https://c2e2.unepdtu.org/events/">Events</a></li>
                    </ul>
                </div>

                <div class="o-grid__col o-grid__col-sm-6 o-grid__col-md-3">
                    <h2 class="p-site-footer__headline">Follow</h2>
                    <ul class="c-social-links">



                        <li class="c-social-links__item">
                            <a href="https://www.linkedin.com/company/copenhagen-centre-on-energy-efficiency/" class="c-social-links__link c-social-links__link--linkedin" title="LinkedIn">LinkedIn</a>
                        </li>

                        <li class="c-social-links__item">
                            <a href="https://www.youtube.com/channel/UCIsNLzs6l2WqmHtj13Ibezw" class="c-social-links__link c-social-links__link--youtube" title="Youtube">Youtube</a>
                        </li>

                    </ul>

                </div>


            </div>
        </div>

        <p class="p-site-footer__legal">
            <span class="p-site-footer__copyright">
        © 2021 UNEP DTU - All rights reserved
      </span>
        </p>

    </div>

</footer>